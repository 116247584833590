import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { StoreModule } from './store/store.module';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { JwtInterceptor } from './core/guards/jwt.interceptor';
import { MatIconModule } from '@angular/material/icon';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, ReCaptchaV3Service } from "ng-recaptcha";


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    FormlyModule.forRoot(),
    StoreModule,
    BrowserModule,
    AppRoutingModule,
    EditorModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    CoreModule,
    MatSnackBarModule,
    FormlyMaterialModule,
    MatIconModule,
    DeviceDetectorModule.forRoot(),
    RecaptchaV3Module
  ],
  providers: [
    ReCaptchaV3Service,
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: 'googleTagManagerId', useValue: 'GTM-T5Z5HDD' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6Le1BW8qAAAAAHhzFx9jdYFNxOS3LV2XP41dtQRJ" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
