import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../constantes/common.constante';
import { AuthFacade } from '../../auth/facade/auth.facade';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  // constructor(private authenticationService: AuthenticationService) {}
  constructor(private authFacade: AuthFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('*** INTERCEPT ****', request.url);
    // console.log('*** RESEND FACTOR ****', Constants.FACTOR_RESEND);

    if (request.url.indexOf('https://viacep.com.br', 0) > -1) {
      return next.handle(request);
    }

    
    if (request.url.indexOf('/site/post/findslug', 0) > -1) {
      return next.handle(request);
    }

    if (
      // request.url === Constants.MAIL_POST ||
      request.url === Constants.ADDRESS_COUNTRIES ||
      request.url === Constants.SITE_PLANS ||
      request.url === Constants.FAQ_LIST ||
      request.url === Constants.SITE_PLANS_ALL ||
      request.url === Constants.SITE_POST_LIST ||
      request.url === Constants.SITE_POST_FILTERS ||
      request.url === Constants.SITE_POST_FINDINCLUDES ||

      request.url === Constants.GROUPCLASS_LIST ||
      request.url === Constants.SITE_FILTERS ||
      request.url === Constants.FACTOR_RESEND ||
      request.url === Constants.GROUPCLASS_LIST ||
      request.url.indexOf(Constants.PLAN_FIND_IDENTIFIER, 0) > -1 ||
      request.url === Constants.SIGNUP ||
      request.url === Constants.SUBSCRIBE ||
      request.url === Constants.SUB_ITEMS ||
      // request.url === Constants.DEVICE_CREATE ||
      request.url === Constants.LOGIN ||
      request.url === Constants.FORGOTINIT ||
      request.url === Constants.FORGOTCONFIRM ||
      request.url === Constants.SUB_WEB ||
      request.url.indexOf('rewrite') >= 1
      ) {

        if (localStorage.getItem('token_site')) {
          // console.log('*** ENTROU EM TOKEN SITE', localStorage.getItem('token_site'));
          request = request.clone({
            setHeaders: {
              Authorization: `${localStorage.getItem('token_site')}`
            }
          });
        }
        return next.handle(request);
    }

    return this.authFacade.token$.pipe(
      switchMap(token => {
        // console.log('*** ENTROU EM AUTH FACADE', token);
        if (token !== null && token !== '') {
          const req = request.clone({
            setHeaders: {
              Authorization: `${token}`
            }
          });
          return next.handle(req);
        }
      })
    );
  }
}
